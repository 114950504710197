import React from 'react';

import { OuterWrapper, NavBar, Brand, Links, Link, WorkArea, Footer } from './styles';

function Container({ children }: { children: React.ReactNode }) {
  return (
    <OuterWrapper>
      <NavBar>
        <Brand>DevBooster</Brand>
        <Links>
          <Link>Settings</Link>
          <Link>About</Link>
        </Links>
      </NavBar>
      <WorkArea>
        {children}
      </WorkArea>
      <Footer>
        &copy; 2022 - FANTASY.CODES
      </Footer>
    </OuterWrapper>
  );
}

export default Container;
