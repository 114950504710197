import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  padding: 24px;

  a {
    text-decoration: none;
  }
`;

export const SimpleCard = styled.div`
  padding: 24px;
  border-radius: 6px;
  border: 1px solid #666;
  background-color: rgba(0, 0, 0, .03);
  color: #333;
  margin-left: 24px;
  transition: background-color .3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, .08);
  }
`;

export const Category = styled.div`
  font-size: 16px;
  color: #333;
`;
