import React from 'react';

interface IButtonProps {
  onClick: () => void;
  children: React.ReactNode | JSX.Element | string;
}

export default function Button(props: IButtonProps) {
  return (
    <button onClick={props.onClick}>{props.children}</button>
  );
}
