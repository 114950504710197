import React from 'react';

import TextField from '@mui/joy/TextField';
import Button from '@mui/joy/Button';

import Extension from '../extension';
import { Wrapper } from './style';
import { copyToClipboard } from '../../utils';
import { hexToRgb } from '../../utils/colors';

const { useState } = React;

export default function ColorPicker() {
  const [currentColor, setCurrentColor] = useState<string>('#ffffff');

  return (
    <Extension name="Color Picker">
      <Wrapper>
        <input
          className="picker"
          type="color"
          value={currentColor}
          onChange={(e) => {
            setCurrentColor(e.target.value);
          }}
        />
        <TextField
          label="In Hex"
          value={currentColor}
          endDecorator={
            <Button size="sm" variant="solid" onClick={() => copyToClipboard(currentColor)}>Copy</Button>
          }
        />
        <TextField
          label="In RGB"
          value={hexToRgb(currentColor)}
          endDecorator={
            <Button size="sm" variant="solid" onClick={() => copyToClipboard(currentColor)}>Copy</Button>
          }
        />
      </Wrapper>
    </Extension>
  );
}
