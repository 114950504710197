import React from 'react';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@mui/joy';


import { Wrapper, Title } from './style';

interface IExtensionProps {
  name: string;
  children: React.ReactNode;
}

export default function Extension(props: IExtensionProps) {
  return (
    <Wrapper>
      <Link to="/"><MuiLink>Back home</MuiLink></Link>
      <Title>{props.name}</Title>
      {props.children}
    </Wrapper>
  );
}
