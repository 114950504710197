import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Wrapper, SimpleCard, Category } from './style';

export default function Home() {
  return (
    <Box>
      <Wrapper>
        <Category>Common</Category>
        <Link to="extension/base64">
          <SimpleCard>Base64 Tool</SimpleCard>
        </Link>
        <Link to="extension/json">
          <SimpleCard>JSON Tool</SimpleCard>
        </Link>
      </Wrapper>
      <Wrapper>
        <Category>Front-end</Category>
        <Link to="extension/css-color">
          <SimpleCard>CSS Color Tool</SimpleCard>
        </Link>
        <Link to="extension/color-picker">
          <SimpleCard>Color Picker</SimpleCard>
        </Link>
      </Wrapper>
    </Box>
  );
}
