import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 24px;
  padding: 12px;
  width: 100%;
  background-color: white;
  border-radius: 4px;
`;

export const Title = styled.h1`

`;
