import React from 'react';

import Textarea from '@mui/joy/Textarea';
import Button from '@mui/joy/Button';

import Extension from '../extension';
import { Wrapper, Actions } from './style';

const { useState, useCallback } = React;

export default function Base64() {
  const [leftValue, setLeftValue] = useState<string>('');
  const [rightValue, setRightValue] = useState<string>('');

  const encode = useCallback((value: string) => {
    setLeftValue(window.btoa(value));
  }, []);
  const decode = useCallback((value: string) => {
    setRightValue(window.atob(value));
  }, []);

  return (
    <Extension name="Base64 Tool">
      <Wrapper>
        <Textarea
          minRows={20}
          size="lg"
          placeholder="Something to be decode"
          value={leftValue}
          onChange={(e) => setLeftValue(e.target.value)}
        />
        <Actions>
          <Button onClick={() => decode(leftValue)}>Decode &gt;</Button>
          <Button onClick={() => encode(rightValue)}>&lt; Encode</Button>
        </Actions>
        <Textarea
          minRows={20}
          size="lg"
          placeholder="Something to be encode"
          value={rightValue}
          onChange={(e) => setRightValue(e.target.value)}
        />
      </Wrapper>
    </Extension>
  );
}
