import React from 'react';

import Extension from '../extension';
import Textarea from '../../components/textarea';
import Button from '../../components/button';

const { useState } = React;

export default function Json() {
  const [content, setContent] = useState<string>('');
  const formatJson = () => {
    setContent(JSON.stringify(JSON.parse(content), undefined, 4));
  };

  return (
    <Extension name="JSON Tool">
      <Textarea value={content} onChange={(val) => setContent(val)} />
      <Button onClick={formatJson}>Format</Button>
    </Extension>
  );
}
