import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;

  .picker {
    margin-right: 12px;
  }

  .JoyInput-root {
    margin-right: 12px;
  }
`;
