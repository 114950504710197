import React from 'react';

interface IInputProps {
  value: string;
  onChange: (value: string) => void;
}

export default function Input(props: IInputProps) {
  return (
    <input type="text" value={props.value} onChange={(e) => {
      const value = e.target.value;
      props.onChange(value);
    }} />
  )
}
