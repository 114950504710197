import React from 'react';

export default function About() {
  return (
    <div>
      <h2>About</h2>
      <p>
        DevBooster is a browser/client application for developers.

        Our goal is to implement tools which used widely by developers all over the world.
      </p>
    </div>
  );
}
