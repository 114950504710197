import React from 'react';

import Extension from '../extension';

import Input from '../../components/input';

import { hexToRgb, rgbToHex } from '../../utils/colors';

const { useState } = React;

export default function CssColor() {
  const [hexValue, setHexValue] = useState<string>('');
  const [rgbValue, setRgbValue] = useState<string>('');

  return (
    <Extension name="CSS Color Tool">
      <h2>Hex To rgb</h2>
      <Input value={hexValue} onChange={(value) => setHexValue(value)} />
      <div>{hexToRgb(hexValue)}</div>
      <h2>Rgb To Hex</h2>
      <Input value={rgbValue} onChange={(value) => setRgbValue(value)} />
      <div>{rgbToHex(rgbValue)}</div>
    </Extension>
  );
}
