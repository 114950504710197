import styled from 'styled-components';

export const OuterWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f2f2f2;
`;

export const NavBar = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 24px;
  box-sizing: border-box;
`;

export const Brand = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin: 0;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Link = styled.a`
  font-size: 16px;
  color: #333;

  &:not(:first-child) {
    margin-left: 12px;
  }
`;

export const WorkArea = styled.main`
  display: flex;
  flex: 1;
`;

export const Footer = styled.footer`
  padding: 24px 0;
  text-align: center;
  font-size: 14px;
  color: #999;
`;

export const Dock = styled.div`

`;

export const DockItem = styled.div`

`;

export const DockApp = styled.div`

`;