import React from 'react';

interface ITextareaProps {
  value: string;
  onChange: (value: string) => void;
}

export default function Textarea(props: ITextareaProps) {
  // const [content, setContent] = useState<string>(props.value || '');

  return (
    <textarea
      cols={30}
      rows={10}
      value={props.value}
      onChange={(e) => {
        const value = e.target.value;
        props.onChange(value);
      }}
    />
  );
}
