export function hexToRgb(hexValue: string) {
  // #fff
  // #fcabca

  // abcdef
  // 先校验是否为合法的 hex 值
  if (/^#(?:[0-9a-fA-F]{3}){1,2}$/.test(hexValue)) {
    // 将 Hex 值转换为 RGB
    const hexValues = hexValue.split('#')[1];

    let colors: Array<string> = [];

    if (hexValues.length === 3) {
      colors = hexValues.split('');
    } else {
      const originColors = hexValues.split('');

      originColors.forEach((color: string, index: number) => {
        if ((index + 1) % 2 === 0) {
          colors.push(`${originColors[index - 1]}${color}`);
        }
      });
    }

    const colorsInRgbArray = colors.map(color => {
      if (color.length === 1) return parseInt(`${color}${color}`, 16);
      return parseInt(color, 16);
    });
    return `rgb(${colorsInRgbArray.join(', ')})`;
  }

  return '';
}

export function rgbToHex(rgbValue: string) {
  const rgbReg = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/;

  // 校验是否为 rgb() 的格式
  if (rgbReg.test(rgbValue)) {
    const rgbValues = rgbReg.exec(rgbValue)?.slice(1, 4);

    return `#${rgbValues?.map((value) => {
      const currentValue = (+value).toString(16);
      if (+value < 16) return `0${currentValue}`;
      return currentValue;
    }).join('')}`;
  }

  return '';
}
