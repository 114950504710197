import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import { CssVarsProvider } from '@mui/joy/styles';

import Home from './pages/home';
import About from './pages/about';

// Extensions
import Base64 from './extensions/base64';
import Json from './extensions/json';
import CssColor from './extensions/css-color';
import ColorPicker from './extensions/color-picker';

import Container from './container';

function App() {
  return (
    <CssVarsProvider>
      <Container>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="extension">
            <Route path="base64" element={<Base64 />} />
            <Route path="json" element={<Json />} />
            <Route path="css-color" element={<CssColor />} />
            <Route path="color-picker" element={<ColorPicker />} />
          </Route>
          <Route path="about" element={<About />} />
        </Routes>
      </Container>
    </CssVarsProvider>
  );
}

export default App;
