import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;

  .JoyTextarea-root {
    flex: 1;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;

  button:last-child {
    margin-top: 12px;
  }
`;
