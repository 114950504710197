/**
 * 复制到剪贴板
 *
 * @param value
 * @returns
 */
export function copyToClipboard(value: string) {
  return navigator.clipboard.writeText(value).then(() => {
    alert('Copied');
  }).catch(() => {
    alert('Copy failed');
  });
}
